import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes } from './constants';
import { useAppSelector } from './store';
import { getIndex } from './utils';
import { useLoadData } from './utils/hooks';
import { AdminShell } from './AdminShell';
import { CarrierDirectory } from './CarrierDirectory';
import { ModalProvider } from './common/Modal';
import { PermissionsCheck } from './common/core/Permissions';
import { JoyrideProvider } from '@Common/components/Joyride/context';
import { ScrollLockProvider } from './ManageUsers/components/common/utils/ScrollLock';
import Joyride from '@Common/components/Joyride/Joyride';
import { ContactUs } from './ContactUs';
import FirebaseAnalytics from '@shamrock-core/common/analytics/firebase-analytics';
// @ts-ignore Import is configured via Webpack externals config
import ShamrockCoreConfig from 'ShamrockCoreConfig';
import { UnsavedChangesAlertProvider } from '@Common/components/UnsavedChangesAlert/UnsavedChangesAlert';

const CMS = React.lazy(() => import('./CMS'));
const Tools = React.lazy(() => import('./Tools'));
const ManageUsers = React.lazy(() => import('./ManageUsers'));
const Authorization = React.lazy(() => import('./Authorization'));
const ExternalRegistration = React.lazy(() => import('./ExternalRegistration'));

FirebaseAnalytics.init(ShamrockCoreConfig.firebaseConfig);

const indexMap = {
  [AppRoutes.CMS]: ['internal.CMS.MarketingUser', 'internal.CMS.HomescreenUser'],
  default: '/settings',
};

const App = () => {
  const isAuthed = useAppSelector((state) => state.adminShell.authed);
  const userPermissions = useAppSelector((state) => state.adminShell.user.permissions);
  const userFeatures = useAppSelector((state) => state.adminShell.userFeatures);
  const cpua3558 = userFeatures.includes('CPUA-3558-WEB');
  const cpua3642 = userFeatures.includes('CPUA-3642');
  const cpua3696 = userFeatures.includes('CPUA-3696');
  const cpua5178 = userFeatures.includes('CPUA-5178');

  const { authFailed, openOoidaForm } = useLoadData();

  if (openOoidaForm) {
    return (
      <>
        <BrowserRouter>
          <ModalProvider>
            <AdminShell />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path={'/register/*'} element={<ExternalRegistration />} />
              </Routes>
            </Suspense>
          </ModalProvider>
        </BrowserRouter>
      </>
    );
  }

  return (
    <>
      {isAuthed && (
        <BrowserRouter>
          <ScrollLockProvider>
            <UnsavedChangesAlertProvider>
              <ModalProvider>
                <JoyrideProvider>
                  <AdminShell />
                  <Joyride />
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route path="/" element={<div>Home of Admin Shell</div>} />
                      <Route
                        path={`${AppRoutes.CMS}/*`}
                        element={
                          <PermissionsCheck
                            permissions={userPermissions}
                            requiredPermissions={['internal.CMS.HomescreenUser', 'internal.CMS.MarketingUser']}
                            fallback={`/${AppRoutes.ADMIN_SHELL_HOME}`}
                          >
                            <CMS />
                          </PermissionsCheck>
                        }
                      />
                      {cpua3696 ? <Route path={`${AppRoutes.MANAGE_USERS}/*`} element={<ManageUsers />} /> : <></>}
                      {cpua5178 ? (
                        <Route
                          path={`${AppRoutes.AUTHORIZATION}/*`}
                          element={
                            <PermissionsCheck
                              permissions={userPermissions}
                              requiredPermissions={['carrierpro.PermissionsAdmin', 'carrierpro.ViewRoles']}
                              fallback={`/${AppRoutes.ADMIN_SHELL_HOME}`}
                            >
                              <Authorization />
                            </PermissionsCheck>
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <Route index element={<Navigate to={getIndex(userPermissions, indexMap)} />} />
                      {/* TODO: Add Permissions */}
                      <Route path={`${AppRoutes.RTSPRO_TOOLS}/*`} element={<Tools />} />
                      {cpua3642 ? (
                        <Route
                          path={`${AppRoutes.CARRIER_DIRECTORY}/*`}
                          element={
                            <PermissionsCheck
                              permissions={userPermissions}
                              requiredPermissions={['carrierpro.RepCompanyAdmin']}
                              fallback={`/${AppRoutes.ADMIN_SHELL_HOME}`}
                            >
                              <CarrierDirectory />
                            </PermissionsCheck>
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <Route index element={<Navigate to={`./${AppRoutes.CMS}`} />} />
                      {cpua3558 ? <Route path={`${AppRoutes.CONTACT_US}/*`} element={<ContactUs />} /> : <></>}
                    </Routes>
                  </Suspense>
                </JoyrideProvider>
              </ModalProvider>
            </UnsavedChangesAlertProvider>
          </ScrollLockProvider>
        </BrowserRouter>
      )}
      {authFailed && <p>User authorization cannot be confirmed at this time.</p>}
    </>
  );
};

export default App;
