import Axios, { AxiosInstance } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { AppRoutes } from '../constants';
import { redirectToLogin } from '../utils';

const { publicPath: publicPath } = config;

export default class Service {
  protected readonly axios: AxiosInstance;
  skipLogout = false;

  constructor(serviceUrl: string) {
    this.axios = this.createAxios(serviceUrl);
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          redirectToLogin(`${publicPath}/${AppRoutes.CMS}`);
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
  }

  createAxios(url: string): AxiosInstance {
    let token;
    let headers;

    try {
      token = LocalAuth.getTokenValue();
    } catch (e) {
      redirectToLogin(`${publicPath}/${AppRoutes.CMS}`);
    }
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return Axios.create({
      baseURL: url,
      headers: headers,
    });
  }
}
