import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/rtk-query';
import { SearchedUser } from '../usersService/usersServiceQuery';

export interface ResendInviteResponse {
  results: { emailRes: string; phoneRes: Record<string, unknown> };
  user: Partial<SearchedUser>;
}

export const getPendingResponseFailures = (pendingUserResponses: ResendInviteResponse[]) => {
  const failures = pendingUserResponses.filter((pendingUserResponse) => {
    const {
      results: { emailRes, phoneRes },
    } = pendingUserResponse;
    const notificationSuccess =
      (emailRes && JSON.parse(emailRes).statusCode === 201) || (phoneRes && phoneRes.acknowledged === true);
    return !notificationSuccess;
  });
  return failures.map((failure) => failure.user);
};
export const tokenServiceApi = createApi({
  reducerPath: 'tokenServiceApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.tokenServiceUrl }),
  endpoints: (builder) => ({
    resendInviteNotifications: builder.mutation<ResendInviteResponse[], Partial<SearchedUser>[]>({
      query: (users) => {
        return {
          url: `?batch=true`,
          method: 'POST',
          data: { batch: users },
        };
      },
    }),
  }),
});

export const { useResendInviteNotificationsMutation } = tokenServiceApi;
