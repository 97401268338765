import { redirectToLogin } from '../utils';
import { AxiosError, AxiosInstance } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { AppRoutes } from '../constants';

const { publicPath: publicPath } = config;

export function addInterceptors(axios: AxiosInstance) {
  axios.interceptors.request.use(
    async (config) => {
      const token = LocalAuth.getTokenValue();
      if (token && config.headers) {
        (config.headers as { Authorization: string }).Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        redirectToLogin(`${publicPath}/${AppRoutes.CMS}`);
      }
      return Promise.reject(error);
    },
  );
}
